app.controller('MortgageController', ['$scope', '$filter', function($scope, $filter) {
    $scope.rate = 12; //rate in %age pm.
    $scope.period = 15; //period in years
    $scope.principal=78000000; //principal amount
    $scope.downpayment=7800000; //down payment 10% if principal

    $scope.calculate = function() {
        var loan  = $scope.principal - $scope.downpayment;
        var rate = $scope.rate/1200;
        var period = $scope.period * 12;
        var rateComponent =  Math.pow((1 + rate), period);
        var payment = (loan * rate * rateComponent) / (rateComponent - 1);
        $scope.output  = $filter('currency')(payment, "Ksh.", 2);
    };

    $scope.calculate();
}]);

app.controller('GoalCalculator', ['$scope', function($scope) {

    $scope.finalValue = 1500000;

    $scope.principalValue = 1000000;

    $scope.rate = 18;

    $scope.calculate = function() {
        var rate = $scope.rate/100;

        var num = Math.log($scope.finalValue/$scope.principalValue);

        var den = Math.log(1+rate);

        $scope.period = (num/den);

        $scope.output = ($scope.period < 1) ? ($scope.period*12).toFixed(0) + " months" : ($scope.period).toFixed(2) + " years";
    };

    $scope.calculate();

}]);

app.directive('mortgageCalculator', function () {
    return {
        templateUrl: '/templates/mortgage.html'
    };
});

app.directive('goalCalculator', function() {
    return {
        templateUrl: '/templates/goal.html'
    }
});