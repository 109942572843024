$(function(){
    $('#eventStart').fdatepicker({
        format: 'yyyy-mm-dd hh:ii:ss',
        disableDblClickSelection: true,
        language: 'vi',
        pickTime: true
    });

    $('#eventEnd').fdatepicker({
        format: 'yyyy-mm-dd hh:ii:ss',
        disableDblClickSelection: true,
        language: 'vi',
        pickTime: true
    });

    $('#article_date').fdatepicker({
        format: 'yyyy-mm-dd',
        disableDblClickSelection: true,
        language: 'vi',
        pickTime: false
    });

    $('#news_date').fdatepicker({
        format: 'yyyy-mm-dd',
        disableDblClickSelection: true,
        language: 'vi',
        pickTime: false
    });

    $('#followup').fdatepicker({
        format: 'yyyy-mm-dd',
        disableDblClickSelection: true,
        language: 'vi',
        pickTime: false
    });
});