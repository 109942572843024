app.service('Users', ['$http', function ($http) {
    this.get=function () {
        return $http.get('/api/users');
    }
}]);

app.service('Groups', ['$http', function($http) {
    this.get= function() {
        return $http.get('/api/groups');
    };
}]);

app.service('Departments', ['$http', function($http) {
    this.get=function() {
        return $http.get('/api/departments');
    };
}]);

app.service('Staff',['$http', function($http) {
    this.get=function() {
        return $http.get('/api/staff');
    }
}]);

app.service('Board', ['$http', function($http) {
    this.get =function() {
        return $http.get('/api/board');
    };
}]);


app.service('Application', ['$http', function($http) {
    this.post= function(url, data) {
        return $http.post(url,data);
    };
}]);

app.service('ApplicationDirectors', ['$http', function($http) {
    this.get=function(id) {
        return $http.get('/api/partners/directors/'+id);
    }
}]);

app.service('NewApplicationDirector', ['$http', function($http) {
    this.post=function(url, data) {
        return $http.post(url, data);
    };
}]);

app.service('NewApplicationContact', ['$http', function($http) {
    this.post=function(url, data) {
        return $http.post(url, data);
    };
}]);

app.service('ApplicationContacts', ['$http', function($http) {
    this.get=function(id) {
        return $http.get('/api/partners/contacts/'+id);
    };
}]);

app.service('NewApplicationShareholder', ['$http', function($http) {
    this.post=function(url, data) {
        return $http.post(url, data);
    };
}]);

app.service('ApplicationShareholders', ['$http', function($http) {
    this.get=function(id) {
        return $http.get('/api/partners/shareholders/'+id);
    };
}]);

app.service('Post',['$http', function($http) {
    this.post=function(url,data) {
        return $http.post(url,data);
    }
}]);

app.service('Roles', ['$http', function ($http) {
     this.get = function() {
         return $http.get('/api/roles');
     }
}]);

app.service('RoleUser', ['$http', function ($http) {
    this.get =function(roleId) {
        return $http.get('/api/role-users/'+roleId);
    }
}]);

app.service('PermissionRole', ['$http', function($http) {
    this.get= function (roleId) {
        return $http.get('/api/role-permissions/'+roleId);
    }
}]);

app.service('Attachments', ['$http', function($http) {
    this.get = function(id) {
       return $http.get('/api/partners/attachments/'+id);
    };
}]);

app.service('AttachmentsWithType', ['$http', function($http) {
    this.get = function(id) {
        return $http.get('/api/partners/attachments-details/'+id);
    };
}]);

app.service('Partners', ['$http', 'TableState', function($http, TableState) {
    this.get = function(tableState) {
        return TableState.getPage(tableState, '/api/partners');
    };
}]);

app.service('PermissionRole', ['$http', function($http) {
    this.get= function (roleId) {
        return $http.get('/api/role-permissions/'+roleId);
    };
}]);

app.service('PartnerProfile', ['$http', function($http) {
    this.get= function () {
        return $http.get('/api/partners/profiles');
    };
}]);

app.service('Tiers', ['$http', function($http) {
    this.get = function () {
        return $http.get('/api/tiers');
    };
}]);

app.service('FAQs', ['$http', function($http) {
   this.get = function() {
       return $http.get('/api/faqs');
   };
}]);

app.service('Reserve', ['$http', function($http) {
    this.create=function(data,slug) {
        return $http.post('/community/events/book/'+slug,data);
    };
}]);

app.service('Category', ['$http', function($http) {
    this.get=function() {
        return $http.get('/api/products/categories');
    };
}]);

app.service('Rate', ['$http', function($http) {
    this.get=function(id) {
        return $http.get('/api/products/rates/'+id);
    };
}]);

app.service('ProductRates', ['$http', function($http) {
    this.get =function() {
        return $http.get('/api/product-ratings');
    }
}]);

app.service('CmsService', ['$http','TableState', function($http, TableState) {
    this.get = function(tableState) {
        return TableState.getPage(tableState, '/api/cms');
    };
}]);

app.service('Article', ['$http','TableState', function($http, TableState) {
    this.get = function(tableState) {
        return TableState.getPage(tableState, '/api/articles');
    };
}]);

app.service('Blog', ['$http','TableState', function($http, TableState) {
    this.get = function(tableState) {
        return TableState.getPage(tableState, '/api/blogs');
    };
}]);

app.service('News', ['$http','TableState', function($http, TableState) {
    this.get = function(tableState) {
        return TableState.getPage(tableState, '/api/news');
    };
}]);

app.service('PartnerApplications', ['TableState', function(TableState) {
    this.get = function (tableState) {
        return TableState.getPage(tableState, '/api/partners/applications');
    };
}]);

app.service('Events', ['TableState', function(TableState) {
    this.get= function(tableState) {
        return TableState.getPage(tableState, '/api/events');
    };
}]);

app.service('PartnerProduct', ['TableState', function( TableState) {
    this.get= function (id, tableState) {
        return TableState.getPage(tableState, '/api/partners/products/'+id);
    };
}]);

app.service('Feature', ['TableState', function(TableState) {
    this.get = function(tableState) {
        return TableState.getPage(tableState, '/api/products/features');
    };
}]);

app.service('Yield', ['TableState', function(TableState) {
    this.get = function(tableState) {
        return TableState.getPage(tableState, '/api/yields');
    };
}]);

app.service('ProductRate', ['$http', function($http) {
    this.get = function(id) {
        return $http.get('/api/product-rating/' +id);
    };
}]);

app.service('Roadshow', ['TableState', function(TableState) {
    this.get = function(tableState) {
        return TableState.getPage(tableState, '/api/roadshows');
    };
}]);

app.service('Attendant', ['$http', function ($http) {
    this.get = function () {
        return $http.get('/api/attendants');
    };
}]);

app.service('IFAs', ['$http','TableState', function($http, TableState) {
    this.get = function(tableState) {
        return TableState.getPage(tableState, '/api/ifas');
    };
}]);

app.service('IFAApplications', ['$http','TableState', function($http, TableState) {
    this.get = function(tableState) {
        return TableState.getPage(tableState, '/api/ifas/applications');
    };
}]);

app.service('Testimony', ['$http','TableState', function($http, TableState) {
    this.get = function(tableState) {
        return TableState.getPage(tableState, '/api/testimonials');
    };
}]);

app.service('FAQCategories', ['$http', function($http) {
    this.get = function() {
        return $http.get('/api/faq-categories');
    };
}]);

app.service('NavigationsService', ['$http','TableState', function($http, TableState) {
    this.get = function(tableState) {
        return TableState.getPage(tableState, '/api/navigations');
    };
}]);

app.service('Galleries', ['$http', function($http) {
    this.get = function() {
        return $http.get('/api/galleries');
    };
}]);

app.service('Uploads', ['$http', function($http) {
    this.getUpload = function(gallery_id) {
        if(gallery_id) {
            return $http.get('/api/uploads?gallery_id=' + gallery_id);
        }
        return $http.get('/api/uploads');
    };
}]);

app.service('CmsGalleries', ['$http', function($http) {
    this.get = function(cms_id) {
        if(cms_id) {
            return $http.get('/api/galleries?cms_id=' + cms_id);
        }
        return $http.get('/api/galleries');
    };
}]);

app.service('TableState', ['$http', function ($http) {
    this.getPage = function (tableState, url) {
        return $http({
            url: url,
            method: 'GET',
            params: {tableState: tableState},
            paramSerializer: '$httpParamSerializerJQLike'
        });
    };
}]);

app.service('CmsSectionsService', ['$http','TableState', function($http, TableState) {
    this.get = function(tableState) {
        return TableState.getPage(tableState, '/api/cms-sections');
    };
}]);
