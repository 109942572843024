app.controller('NavigationsController', ['$scope', '$auth', 'NavigationsService', function ($scope, $auth, NavigationsService) {
    $scope.navigations=[];
    $scope.displayedNavigations=[];
    $scope.itemsByPage = 10;

    $scope.callServer = function callServer(tableState) {
        NavigationsService.get(tableState)
            .then(function (response) {
                $scope.navigations = response.data.data;
                $scope.displayedNavigations=[].concat($scope.navigations);

                tableState.pagination.numberOfPages = response.data.meta.pagination.totalItemCount;
            });
    };

}]);