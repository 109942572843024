app.controller('TestimonialsController', ['Testimony', '$scope', function (Testimony, $scope) {
    $scope.itemsByPage = 10;

    $scope.callServer = function callServer(tableState) {
        Testimony.get(tableState)
            .then(function (response) {
                $scope.testimonials = response.data.data;
                $scope.displayedTestimonials=[].concat($scope.testimonials);
                tableState.pagination.numberOfPages = response.data.meta.pagination.totalItemCount;
            });
    };

    $scope.convert =  function ( date ) {
        return new Date(date.replace(/-/g, "/"));
    };
}]);