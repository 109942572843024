app.controller('CmsController', ['$scope', 'Users','Groups', '$auth','CmsService', function ($scope,Users,Groups, $auth, CmsService) {
    $scope.cms=[];
    $scope.displayedCms=[];
    $scope.groupObj={};
    $scope.userObj={};
    $scope.itemsByPage = 10;

    var groups=[];
    var users=[];

    Groups.get().then(function(response) {
        groups=response.data;

        for(var i in groups) {
            $scope.groupObj[groups[i].id] = groups[i].name;
        }
    });

    Users.get().then(function(response) {
        users=response.data;

        for(var i in users) {
            $scope.userObj[users[i].id] = users[i].firstname+" "+users[i].lastname;

        }
    });

    $scope.callServer = function callServer(tableState) {
        CmsService.get(tableState)
            .then(function (response) {
                $scope.cms = response.data.data;
                $scope.displayedCms=[].concat($scope.cms);

                tableState.pagination.numberOfPages = response.data.meta.pagination.totalItemCount;
            });
    };

}]);