app.controller('RatesController', ['$scope','Rate', function($scope,Rate) {
    $scope.displayedCollection = [];
    $scope.itemsByPage=6;

   $scope.$watch($scope.id, function() {
       Rate.get($scope.id).then(
           function(response) {
               $scope.rates=response.data;
               $scope.displayedCollection=[].concat($scope.data);
           },
           function(response) {
               $scope.errors=response.data;
               console.log($scope.errors);
           });
   });
}]);

app.controller("DisplayRatesController", ['$scope', '$http', function($scope, $http) {
    $scope.slider = {
        value: 6,
        options: {
            floor: 0,
            ceil: 12,
            step: 1,
            showSelectionBarFromValue: 0
        }
    };

    function setRates(period) {
        $scope.rateusd = $scope.rates[period].rateusd;
        $scope.rateksh = $scope.rates[period].rateksh;
    }

    $scope.changeRates = function() {
        if($scope.id===12) {
            $http.get('/api/investments/rates/ris').then(
                function(response) {
                    console.log(response.data);
                    $scope.rates = response.data;

                    $scope.slider.options.ceil = Object.keys($scope.rates).length;

                    if($scope.slider.value===0) {
                        $scope.rateusd = 0;
                        $scope.rateksh = 0;
                    }

                    $scope.rateusd = $scope.rates[$scope.slider.value].rateusd;
                    $scope.rateksh = $scope.rates[$scope.slider.value].rateksh;
                },
                //----- errors -------//
                function(response) {
                    console.log(response.data);
                });
        }
        else {
            $http.get('/api/investments/rate/'+ $scope.id).then(
                function(response) {
                    $scope.rates = response.data;

                    switch ($scope.slider.value) {
                        case 0: setRates(0); break;
                        case 1: setRates(1); break;
                        case 2: setRates(1); break;
                        case 3: setRates(3); break;
                        case 4: setRates(3); break;
                        case 5: setRates(3); break;
                        case 6: setRates(6); break;
                        case 7: setRates(6); break;
                        case 8: setRates(6); break;
                        case 9: setRates(9); break;
                        case 10:setRates(9);break;
                        case 11:setRates(9);break;
                        case 12:setRates(12);break;
                        default:setRates(0);break;
                    }
                },
                function(response) {
                    $scope.errors = response.data;
                });
            }
        };

    $scope.$watch($scope.id, function() {

        $scope.changeRates();

        if($scope.id===12) {
            $scope.period = 'years';
            $scope.slider.value=2;
        }else {
            $scope.period = 'months';
            $scope.slider.value=6;
        }
    });

    $scope.$watch(function() {
            return $scope.slider.value;
        },
        function(){
            $scope.changeRates();
        });
}]);

app.controller('ProductsRatesController', ['$scope','$http', function($scope, $http) {
    $scope.slider = {
        value: 6,
        options: {
            floor: 0,
            ceil: 12,
            step: 1,
            showSelectionBarFromValue: 0
        }
    };
    $scope.period= 'months';

    function setRates(period) {
        $scope.rateusd = $scope.rates[period].rateusd;
        $scope.rateksh = $scope.rates[period].rateksh;
    }

    $scope.changeRates = function() {
        $http.get('/api/investment-products/rate/'+ $scope.name)
            .then(function(response) {
                $scope.rates = response.data;

                switch ($scope.slider.value) {
                    case 0: setRates(0); break;
                    case 1: setRates(1); break;
                    case 2: setRates(1); break;
                    case 3: setRates(3); break;
                    case 4: setRates(3); break;
                    case 5: setRates(3); break;
                    case 6: setRates(6); break;
                    case 7: setRates(6); break;
                    case 8: setRates(6); break;
                    case 9: setRates(9); break;
                    case 10:setRates(9);break;
                    case 11:setRates(9);break;
                    case 12:setRates(12);break;
                    default:setRates(0);break;
                }
            },
            function(response) {
                console.log(response.data);
            }
        );
    };

    $scope.risRates = function() {
        $http.get('/api/investments/rates/ris').then(
            function(response) {
                $scope.rates = response.data;

                $scope.slider.options.ceil = Object.keys($scope.rates).length;

                if($scope.slider.value===0) {
                    $scope.rateusd = 0;
                    $scope.rateksh = 0;
                }

                $scope.rateusd = $scope.rates[$scope.slider.value].rateusd;
                $scope.rateksh = $scope.rates[$scope.slider.value].rateksh;
            },
            //----- errors -------//
            function(response) {
                console.log(response.data);
            });
    };

    $scope.$watch($scope.id, function() {
        if($scope.name === 'coop_premier') {
            $scope.slider.value = 2;
            $scope.period = 'years';
            $scope.risRates();
        }
        else
        {
            // $scope.name = 'chys';
            // $scope.period = 'months';
            $scope.changeRates();
        }

    });

    $scope.$watch(function() {
            return $scope.slider.value;
        },
        function(){
            if($scope.name === 'coop_premier') {
                $scope.period = 'years';
                $scope.risRates();
            }
            else
            {
                // $scope.name = 'chys';
                // $scope.period = 'months';
                $scope.changeRates();
            }

        });

    $scope.$watch(
        function() {
            return $scope.name;
        },

        function() {
            if($scope.name === 'coop_premier') {
                $scope.slider.value = 2;
                $scope.period = 'years';
                $scope.risRates();
            }
            else
            {
                // $scope.name = 'chys';
                // $scope.period = 'months';
                $scope.changeRates();
            }
        });
}]);
