app.controller('FeaturesController', ['$scope','Feature', function($scope,Feature) {
    $scope.features = [];
    $scope.displayedFeatures=[];
    $scope.itemsByPage = 10;

    $scope.callServer = function(tableState) {
        Feature.get(tableState).then(
            function(response) {
                $scope.features = response.data.data;
                $scope.displayedFeatures = [].concat($scope.features);

                tableState.pagination.numberOfPages = response.data.meta.pagination.totalItemCount;
            }
        );
    }
}]);