app.controller('CategoriesController', ['$scope','Category', function($scope, Category) {
    $scope.displayedCollection=[];
    $scope.itemsByPage = 15;

    Category.get().then(function(response) {
            $scope.categories=response.data;
            $scope.displayedCollection=[].concat($scope.categories);
    },
    function(response) {
        $scope.errors=response.data;
        console.log($scope.errors);
    });
}]);