app.controller('RoadshowsController', ['$scope', 'Roadshow', function($scope, Roadshow) {
    $scope.itemsByPage = 20;
    $scope.displayedRoadshows = [];

    $scope.callServer = function (tableState) {
        Roadshow.get(tableState).then(
            function(response) {
                $scope.roadshows = response.data.data;
                $scope.displayedRoadshows = [].concat($scope.roadshows);
                tableState.pagination.numberOfPages = response.data.meta.pagination.totalItemCount;
            }, function (response) {
                $scope.errors = response.data;
            });
    };

    $scope.convert =  function ( date ) {
        return new Date(date.replace(/-/g, "/"));
    };
}]);


app.controller('AttendantsCtrl', ['$scope', 'Attendant', '$http', 'Flash',  function ($scope, Attendant, $http, Flash) {
    $scope.formData = [];
    Attendant.get('/api/attendants').then(
        function (response) {
            $scope.attendants = response.data;
        },
        function (errors) {
            $scope.errors = errors.data;
        });

    $scope.getAttendants = function(val) {
        return $http.get('/api/attendants/search', {
            params: {
                email: val
            }
        }).then(function(res){
            var attendants = [];
            angular.forEach(res.data.results, function(item){
                attendants.push(item);
            });
            return attendants;
        });
    };

    var notify = function (action) {
        var message = '<strong> Yah! </strong> Your details were ' + action + ' successfully';
        Flash.create('success', message, 5000, {class: 'success'}, true);
    };

    $scope.saveAttendantDetails  = function () {

        if($scope.email === undefined) {
            $scope.email =  $scope.dataSet;
        }

        $http.post('/roadshow/attendance', {'firstname': $scope.firstname, 'lastname' : $scope.lastname, 'email' : $scope.email, 'telephone' : $scope.telephone, 'id' : $scope.id })
            .then(
            function (response) {
                $scope.response = response.data;
                notify(response.data);
                $scope.dataSet = '';
                $scope.errors = [];
                $scope.firstname = '';
                $scope.lastname = '';
                $scope.email = '';
                $scope.telephone = '';

                $scope.fillForm();
            }, function (response) {
                $scope.errors = response.data.errors;
            });
    };

    $scope.fillForm = function () {
        $scope.firstname = $scope.dataSet.firstname;
        $scope.lastname = $scope.dataSet.lastname;
        $scope.telephone = $scope.dataSet.telephone;
        $scope.email = $scope.dataSet.email;
    };

    $scope.$watch(function() {
        return $scope.dataSet;
    }, function() {
        $scope.email = $scope.dataSet.email;
    });
}]);

app.controller('SurveyController', ['$scope', '$http', 'Flash',function ($scope, $http, Flash) {

    $scope.formData = {};
    $scope.getAttendants = function(val) {
        return $http.get('/api/attendants/search', {
            params: {
                email: val
            }
        }).then(function(res){
            var attendants = [];
            angular.forEach(res.data.results, function(item){
                attendants.push(item);
            });
            return attendants;
        });
    };

    var notify = function () {
        var message = 'Thank you for taking the time to complete the survey!';
        Flash.create('success', message, 5000, {class: 'success'}, true);
    };

    $scope.saveSurvey  = function () {
        $scope.formData.email = $scope.email;
        $scope.errors = [];
        $http.post('/roadshow/survey', $scope.formData)
            .then(
            function (response) {
                $scope.response = response.data;
                notify();
                $scope.dataSet = '';
                $scope.formaData = {};

                $scope.email = $scope.dataSet.email;
            }, function (response) {
                $scope.errors = response.data.errors;
            });
    };

    $scope.$watch(function() {
        return $scope.dataSet;
    }, function() {
        $scope.email = $scope.dataSet.email;
        if($scope.email === undefined) {
            $scope.email =  $scope.dataSet;
        }
    });
}]);