app.controller('EventsController', ['$scope','Events', function($scope,Events) {
    $scope.displayedCollection=[];
    $scope.itemsByPage = 7;

    $scope.callServer = function(tableState) {
        Events.get(tableState)
            .then(function(response){
                $scope.events=response.data.data;
                $scope.displayedCollection=[].concat($scope.events);

                tableState.pagination.numberOfPages = response.data.meta.pagination.totalItemCount;
            });
    }
}]);

app.controller('NotificationsController',['$scope','Reserve','$window', function($scope, Reserve,$window) {
    $scope.data={};
    $scope.data.notifications = [{'notification':10, 'duration':"1"}];

    $scope.addNotification = function() {
        var newNotification = $scope.data.notifications.length+1;
        $scope.data.notifications.push({'notification':10, 'duration':"1"});
    };
    $scope.removeNotification = function() {
        var lastNotification = $scope.data.notifications.length-1;
        $scope.data.notifications.splice(lastNotification);
    };

    $scope.submitReservation = function() {
        Reserve.create($scope.data, $scope.slug).then(
            function(response) {

                if(response.data!="success") {
                    $scope.validations = response.data;
                }
                else {
                    $window.location.href = '/community/events';
                }
                //Flash some message here
            },
            function(errors) {
                $scope.errors =errors.data;
            }
        );
    };

}]);

app.controller('AddEventController', ['$scope', '$window','Upload','$timeout','Flash', function($scope,$window,Upload,$timeout, Flash) {
    $scope.uploadEvent = function(file) {
        console.log($scope.data);
        file.upload = Upload.upload({
            url: '/users/events/add',
            data: $scope.data
        });

        file.upload.then(function (response) {
            if(response.data!=='success') {
                $scope.validations=response.data;
            }
            else {
                var message = "Event has been successfully created";
                Flash.create('success', message, 60000, {class: 'success', id: 'event-created'}, true);

                $window.location.href = '/admin';
            }
            $timeout(function () {
                file.result = response.data;
            });
        }, function (response) {
            if (response.status > 0)
                $scope.errorMsg = response.status + ': ' + response.data;
        }, function (evt) {
            // Math.min is to fix IE which reports 200% sometimes
            file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
        });
    };
}]);