app.controller('PartnerApplicationsController', ['$scope','PartnerApplications', function($scope, PartnerApplications) {
    $scope.applications = [];
    $scope.displayedApplications = [];

    $scope.callServer = function (tableState) {
        PartnerApplications.get(tableState).then(
            function(response) {
                $scope.applications = response.data.data;
                $scope.displayedApplications = [].concat($scope.applications);

                tableState.pagination.numberOfPages = response.data.meta.pagination.totalItemCount;
            },
            function(errors) {
                $scope.errors = errors.data;
            }
        );
    };

}]);

app.controller('PartnerOwnershipController', ['$scope','ApplicationDirectors', function($scope, ApplicationDirectors) {
    $scope.displayedCollection = [];

    $scope.$watch($scope.id, function() {
        ApplicationDirectors.get($scope.id).then(
            function(response) {

                $scope.directors = response.data;
                $scope.displayedCollection = [].concat($scope.directors);
            },
            function(errors) {
                $scope.errors = errors.data;
            }
        );
    });
}]);

app.controller('PartnerShareholderController', ['$scope','ApplicationShareholders', function($scope,ApplicationShareholders) {
    $scope.displayedCollection = [];

    $scope.$watch($scope.id, function() {
        ApplicationShareholders.get($scope.id).then(
            function(response) {
               $scope.shareholders = response.data;
                $scope.displayedCollection = [].concat($scope.shareholders);
            },function(errors) {
                $scope.errors = errors.data;
            }
        );
    });
}]);

app.controller('PartnerContactsController', ['$scope','ApplicationContacts', function($scope, ApplicationContacts) {
    $scope.displayedCollection = [];
    $scope.$watch($scope.id, function() {
        ApplicationContacts.get($scope.id).then(
            function(response) {
                $scope.contacts = response.data;
                $scope.displayedCollection = [].concat($scope.contacts);
            }, function(errors) {
                $scope.errors = errors.data;
            }
        );
    });
}]);

app.controller('PartnerAttachmentsController', ['$scope', 'AttachmentsWithType', function($scope, AttachmentsWithType) {
    $scope.displayedCollection=[];

    $scope.$watch($scope.id, function() {
        AttachmentsWithType.get($scope.id).then(
            function(response) {
                console.log(response.data);

                $scope.attachments= response.data;
                $scope.displayedCollection = [].concat($scope.attachments);
            },function(errors) {
                $scope.errors= errors.data;
            }
        );
    });
}]);