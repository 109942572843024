app.controller('PartnersController', ['$scope','$window', '$http',
    '$state','Application','NewApplicationDirector','ApplicationDirectors',
    'NewApplicationContact', 'ApplicationContacts','NewApplicationShareholder','ApplicationShareholders',
    function($scope, $window,$http,$state,Application,NewApplicationDirector,
             ApplicationDirectors, NewApplicationContact, ApplicationContacts,
             NewApplicationShareholder, ApplicationShareholders) {
        $scope.terms=false;
        $scope.errors=false;
        $scope.formData={};
        $scope.contactForm={};
        $scope.company = false;
        $scope.formData.id=false;
        $scope.directorForm={};
        $scope.shareholderForm={};

        //Let the user accept terms of use
        $scope.acceptTermsOfUse = function() {
            if($scope.terms) {
                $window.location.href="/partners/process";
            }
        };

        //Save Registration Details
        $scope.saveRegistrationDetails=function() {
            Application.post('/partners/application/registration', { name:$scope.formData.name, address:$scope.formData.address, email: $scope.formData.email, telephone: $scope.formData.telephone, website: 'http://'+$scope.formData.website})
                .then(function(response) {
                    $scope.errors=false;
                    $scope.status=response.data;
                    $scope.formData.id=$scope.status.id;
                    $state.go('profile');

                }, function(response) {
                    $scope.errors=response.data;
                    $state.go('registration');
                });

        };

    //Check whether the application is new or existing. If new, take the user to the first step of registration
    $scope.checkStatus = function() {
        if(! $scope.formData.id) {
            $state.go('registration');
        };
    };

    //Save profile details
    $scope.saveProfileDetails=function()
    {
        $scope.add_director=true;
        //make a copy of formdata
        $scope.formDataCopy=$scope.formData;
        $scope.errors=false;
        $scope.checkStatus();
        Application.post('/partners/application/update',$scope.formData).then(function(response) {
            $scope.formData=$scope.formDataCopy;
            //update the copy of form data
            //$scope.formDataCopy=$scope.formData;
        }, function(response) {
            //reset formData incase of errors
            $scope.formData=$scope.formDataCopy;
            $scope.errors=response.data;
            $state.go('profile');
        });
        $state.go('ownership');
    };

    //Save Application Director Details
    $scope.addDirector=function() {
        $scope.add_director=false;
        //$scope.formData=$scope.formDataCopy;
        $scope.errors=false;
        console.log($scope.formData);
        console.log($scope.formDataCopy);
        NewApplicationDirector.post('/partners/directors/add', {id:$scope.formData.id, name:$scope.directorForm.directorName, qualifications:$scope.directorForm.directorQualifications})
            .then(function(response) {
                $scope.formData=$scope.formDataCopy;
                ApplicationDirectors.get($scope.formData.id)
                    .then(function(response) {
                        $scope.currentDirectors=response.data;
                        $scope.formData=$scope.formDataCopy;
                    }, function(errors) {
                        $scope.errors=errors.data;

                        $scope.formData=$scope.formDataCopy;
                    });
                $state.go('ownership');
            }, function(response) {
                $scope.formData=$scope.formDataCopy;
                $scope.errors=response.data;
                $state.go('ownership');
            });
    };

        $scope.addContact=function() {
            $scope.checkStatus();
            NewApplicationContact.post('/partners/contacts/add', { applications_id: $scope.formData.id, name: $scope.contactForm.name, email: $scope.contactForm.email, position: $scope.contactForm.position }).then(
                function(response) {
                    $scope.formData=response.data;
                    $scope.formDataCopy=$scope.formData;

                    ApplicationContacts.get($scope.formData.id).then(
                        function(response) {
                            $scope.formData=$scope.formDataCopy;
                            $scope.currentContacts = response.data;
                            $scope.contactForm={};
                            $state.go('ownership');
                        },
                        function (errors) {
                            $scope.formData=$scope.formDataCopy;
                            $scope.errors=errors.data;
                            $state.go('ownership');
                        });
                },
                function(errors) {
                    console.log(errors.data);
                    $scope.formData=$scope.formDataCopy;
                    $scope.errors=errors.data;
                    $state.go('ownership');
                });
        };

        $scope.addShareholder=function() {
            $scope.checkStatus();
            //Post data to shareholders
            NewApplicationShareholder.post('/partners/shareholders/add', { applications_id:$scope.formData.id, name: $scope.shareholderForm.name,email:$scope.shareholderForm.email, shareholding: $scope.shareholderForm.shareholding  }).then(
                function(response) {
                    $scope.formData=response.data;
                    $scope.formDataCopy=$scope.formData;

                    ApplicationShareholders.get($scope.formData.id).then(
                        function (response) {
                            console.log(response.data);
                            $scope.shareholders=response.data;
                            $state.go('ownership');
                        },
                        function (errors) {
                            $scope.errors= errors.data;
                            $scope.formData=$scope.formDataCopy;
                            $state.go('ownership');
                        });
                },
                function (errors) {
                    $scope.errors= errors.data;
                    $scope.formData=$scope.formDataCopy;
                    $state.go('ownership');
                });
            //Display the existing shareholders
        };

        $scope.submitOwnership = function() {
            $scope.checkStatus();
            $scope.formDataCopy=$scope.formData;

            Application.post('/partners/application/update',$scope.formData).then(
                function(response) {
                    console.log($scope.formData);
                    $state.go('clients');
                },
                function (errors) {
                    $scope.errors= errors.data;
                    $scope.formData=$scope.formDataCopy;
                    $state.go('clients');
                }
            );
        };

        $scope.submitClientAcquisition = function() {
            $scope.checkStatus();
            $scope.formDataCopy=$scope.formData;

            Application.post('/partners/application/update',$scope.formData).then(
                function(response) {
                    console.log($scope.formData);
                    $window.location.href='/partners/attachments/'+$scope.formData.id;
                },
                function (errors) {
                    $scope.errors= errors.data;
                    $scope.formData=$scope.formDataCopy;
                    $state.go('clients');
                }
            );
        };
    }]);

app.controller('PartnersController', ['$scope', 'Partners', function($scope, Partners) {
    $scope.displayedCollection = [];

    $scope.callServer = function (tableState) {
        Partners.get(tableState).then(function(response) {
            $scope.partners = response.data.data;
            $scope.displayedCollection=[].concat($scope.partners);

            tableState.pagination.numberOfPages = response.data.meta.pagination.totalItemCount;

        }, function(response) {
            $scope.errors = response.data;
        });
    };


}]);


app.controller('TiersController', ['$scope','Tiers', function($scope, Tiers) {
    $scope.displayedCollection = [];

    Tiers.get().then(function(response) {
        $scope.tiers =response.data;
        $scope.displayedCollection = [].concat($scope.tiers);
    }, function(response) {
        $scope.errors = response.data;
    });

}]);

app.controller('ProductsController', ['$scope', 'PartnerProduct', function ($scope, PartnerProduct) {
    $scope.displayedProducts = [];
    $scope.itemsByPage = 15;

    $scope.callServer = function(tableState) {
        $scope.$watch($scope.id, function () {
            PartnerProduct.get($scope.id, tableState)
                .then(function (response) {
                    $scope.products = response.data.data;
                    $scope.displayedProducts = [].concat($scope.products);
                    tableState.pagination.numberOfPages = response.data.meta.pagination.totalItemCount;
                });
        });
    };
}]);
