app.controller('DepartmentsController', ['$scope','Departments', function($scope,Departments) {
    $scope.departments=[];
    $scope.displayedDepartments=[];
    $scope.itemsByPage = 15;

    Departments.get()
        .then(function(response){
            $scope.departments=response.data;
            $scope.displayedDepartments=[].concat($scope.departments);
            console.log($scope.displayedDepartments);
        });
}]);