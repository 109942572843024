app.controller('BlogsController', ['$scope', 'Blog', function($scope, Blog) {
    $scope.itemsByPage = 10;

    $scope.callServer = function callServer(tableState) {
        Blog.get(tableState)
            .then(function (response) {
                $scope.blogs = response.data.data;
                $scope.displayedBlogs=[].concat($scope.blogs);
                tableState.pagination.numberOfPages = response.data.meta.pagination.totalItemCount;
            });
    };

    $scope.convert =  function ( date ) {
        return new Date(date.replace(/-/g, "/"));
    };
}]);