app.controller('SubscriptionController', ['$scope','$http','$window', 'Flash', function($scope, $http,$window, Flash) {

    $scope.subscribe = function() {
        $http.post('/subscribe',$scope.subscriptionForm).then(
            function(response) {
                if(response.data==1) {
                    //Show info that subscription is successful
                    angular.element(document).find(".reveal-overlay").remove();
                    angular.element(document).find(".is-reveal-open").removeClass();
                    angular.element(document).find(".Subscribe_col").remove();
                    Flash.create('success', "Thank you for subscribing to our Newsletter!", 5000, {class: 'Subscription_notification fade'}, true);
                }
            },
            function(response) {
                $scope.errors=response.data.error;
            }
        );
    }
}]);