app.controller('CmsSectionsController', ['$scope', 'CmsService', '$auth', 'CmsSectionsService', function ($scope, CmsService, $auth, CmsSectionsService) {
    $scope.cmsSections=[];
    $scope.displayedCmsSections=[];
    $scope.cmsObj={};
    $scope.itemsByPage = 10;

    var cms=[];

    CmsService.get({pagination: {number: 50}}).then(function(response) {
        cms=response.data.data;

        for(var i in cms) {
            $scope.cmsObj[cms[i].id] = cms[i].name;

        }

    });

    $scope.callServer = function callServer(tableState) {
        CmsSectionsService.get(tableState)
            .then(function (response) {
                $scope.cmsSections = response.data.data;
                $scope.displayedCmsSections=[].concat($scope.cmsSections);

                tableState.pagination.numberOfPages = response.data.meta.pagination.totalItemCount;
            });
    };

}]);