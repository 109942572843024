app.controller('GalleriesController',['$scope', 'Galleries', function($scope, Galleries) {
    $scope.displayedCollection = [];
    $scope.itemsByPage = 10;

    Galleries.get().then(function(response) {
        $scope.galleries = response.data;
        $scope.displayedCollection = [].concat($scope.galleries);
    }, function(response) {
        $scope.errors = response.data;
    });
}]);

app.controller('CmsGalleriesController',['$scope', 'CmsGalleries', function($scope, CmsGalleries) {
    $scope.displayedCollection = [];
    $scope.itemsByPage = 10;
    $scope.cms_id = null;

    $scope.$watch('cms_id', function () {

        CmsGalleries.get($scope.cms_id)
            .then(function(response) {
                $scope.galleries = response.data;
                $scope.displayedCollection = [].concat($scope.galleries);
            }, function(response) {
                $scope.errors = response.data;
            });

    });

}]);