app.controller('BoardController', ['$scope','Board', function ($scope, Board) {
    $scope.board=[];
    $scope.displayedBoard=[];
    $scope.itemsByPage = 15;

    Board.get()
        .then(function (response) {
            $scope.board=response.data;
            $scope.displayedBoard=[].concat($scope.board);
        });
}]);