app.controller('HomeController', ['$scope','$interval', '$window', function($scope, $interval, $window) {

    $scope.scroll=false;
    $scope.padding=false;
    $scope.small=false;
    $scope.menuStatus=true;

    $scope.slides = [
        {image: '/images/slider/img1.jpg', description: 'Image 00'},
        {image: '/images/slider/img2.jpg', description: 'Image 01'},
        {image: '/images/slider/img3.jpg', description: 'Image 02'}
    ];

    $scope.currentIndex=0;

    $scope.setCurrentIndex=function(index) {
        $scope.currentIndex=index;
    };

    $scope.isCurrentSlideIndex=function(index) {
        return ($scope.currentIndex===index);
    };

    function changeIndex() {
        var size=$scope.slides.length;
        var position = $scope.currentIndex+1;

        if(position>=size) {
            $scope.currentIndex=0;
        }else {
            $scope.currentIndex+=1;
        }
    }

    $interval(changeIndex, 5000);

}]);
app.controller('HomeController', ['$scope','$interval', '$window', function($scope, $interval, $window) {

    $scope.scroll=false;
    $scope.padding=false;
    $scope.small=false;
    $scope.menuStatus=true;

    $scope.slides = [
        {image: '/images/slider/img1.jpg', description: 'Image 00'},
        {image: '/images/slider/img2.jpg', description: 'Image 01'},
        {image: '/images/slider/img3.jpg', description: 'Image 02'}
    ];

    $scope.currentIndex=0;

    $scope.setCurrentIndex=function(index) {
        $scope.currentIndex=index;
    };

    $scope.isCurrentSlideIndex=function(index) {
        return ($scope.currentIndex===index);
    };

    function changeIndex() {
        var size=$scope.slides.length;
        var position = $scope.currentIndex+1;

        if(position>=size) {
            $scope.currentIndex=0;
        }else {
            $scope.currentIndex+=1;
        }
    }

    $interval(changeIndex, 5000);

}]);

app.directive('scrollPosition', ["$window", function($window) {
    return {
        scope: {
            scroll:'=scrollPosition'
    },
        link: function(scope, element, attrs) {
            var windowEl = angular.element($window);
            var handler = function() {
                    if(windowEl.scrollTop() >= 578) {
                        scope.scroll = true;
                    } else {
                        scope.scroll = false;
                    }
            };
            windowEl.on('scroll', scope.$apply.bind(scope, handler));
            handler();

        }
    };
}]);

app.directive('scrollAbout', ["$window", function($window) {
    return {
        scope: {
            scroll:'=scrollAbout'
        },
        link: function(scope, element, attrs) {
            var windowEl = angular.element($window);
            var handler = function() {
                if (windowEl.scrollTop() >= 580) {
                    scope.scroll = true;
                } else {
                    scope.scroll = false;
                }
            };
            windowEl.on('scroll', scope.$apply.bind(scope, handler));
            handler();

        }
    };
}]);

app.directive('scrollProduct', ["$window", function($window) {
    return {
        scope: {
            scroll:'=scrollProduct'
        },
        link: function(scope, element, attrs) {
            var windowEl = angular.element($window);
            var handler = function() {

                if (windowEl.scrollTop() >= 558) {
                    scope.scroll = true;
                } else {
                    scope.scroll = false;
                }
            };
            windowEl.on('scroll', scope.$apply.bind(scope, handler));
            handler();

        }
    };
}]);

app.directive('mediumScroll', ["$window", function($window) {
    return {
        scope: {
            scroll:'=mediumScroll'
        },
        link: function(scope, element, attrs) {
            var windowEl = angular.element($window);
            var handler = function() {

                if (windowEl.scrollTop() >= 560) {
                    scope.scroll = true;
                } else {
                    scope.scroll = false;
                }
            };
            windowEl.on('scroll', scope.$apply.bind(scope, handler));
            handler();

        }
    };
}]);

app.directive('faqScroll', ["$window", function($window) {
    return {
        scope: {
            scroll:'=faqScroll'
        },
        link: function(scope, element, attrs) {
            var windowEl = angular.element($window);
            var handler = function() {
                if (windowEl.scrollTop() >= 217) {
                    scope.scroll = true;
                } else {
                    scope.scroll = false;
                }
            };
            windowEl.on('scroll', scope.$apply.bind(scope, handler));
            handler();

        }
    };
}]);

app.directive('faqsmallScroll', ["$window", function($window) {
    return {
        scope: {
            scroll:'=faqsmallScroll'
        },
        link: function(scope, element, attrs) {
            var windowEl = angular.element($window);
            var handler = function() {
                if (windowEl.scrollTop() >= 200) {
                    scope.scroll = true;
                } else {
                    scope.scroll = false;
                }
            };
            windowEl.on('scroll', scope.$apply.bind(scope, handler));
            handler();

        }
    };
}]);

app.directive('smallScroll', ["$window", function($window) {
    return {
        scope: {
            scroll:'=smallScroll'
        },
        link: function(scope, element, attrs) {
            var windowEl = angular.element($window);
            var handler = function() {
                if (windowEl.scrollTop() >= 367) {
                    scope.scroll = true;
                } else {
                    scope.scroll = false;
                }
            };
            windowEl.on('scroll', scope.$apply.bind(scope, handler));
            handler();

        }
    };
}]);

app.directive('eventScroll', ["$window", function($window) {
    return {
        scope: {
            scroll:'=eventScroll'
        },
        link: function(scope, element, attrs) {
            var windowEl = angular.element($window);
            var handler = function() {
                if (windowEl.scrollTop() >= 562) {
                    scope.scroll = true;
                } else {
                    scope.scroll = false;
                }
            };
            windowEl.on('scroll', scope.$apply.bind(scope, handler));
            handler();

        }
    };
}]);

app.controller('TestimonialsController', ['$scope', function ($scope) {
    $scope.breakpoints = [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }, {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ];
}]);



//app.animation('.slide', function() {
//    return {
//
//        addClass: function (element, className, done) {
//            if (className == 'ng-hide') {
//                jQuery(element).fadeOut(1000, done);
//            }
//            else {
//                done();
//            }
//        },
//
//        removeClass: function(element, className, done) {
//            if (className == 'ng-hide') {
//                jQuery(element).fadeIn(500, done);
//            }
//            else {
//                done();
//            }
//        }
//    }
//});