app.controller('FaqsController',['$scope', 'FAQs', function($scope, FAQs) {
    $scope.displayedCollection = [];
    $scope.itemsByPage = 4;

    FAQs.get().then(function(response) {
        $scope.faqs =response.data;
        $scope.displayedCollection = [].concat($scope.faqs);
    }, function(response) {
        $scope.errors = response.data;
    });
}]);