app.controller('FaqCategoriesController',['$scope', 'FAQCategories', function($scope, FAQCategories) {
    $scope.displayedCollection = [];
    $scope.itemsByPage = 10;

    FAQCategories.get().then(function(response) {
        $scope.faqCategories = response.data;
        $scope.displayedCollection = [].concat($scope.faqCategories);
    }, function(response) {
        $scope.errors = response.data;
    });
}]);