app.controller('UsersController', ['$scope','Users','$auth', function ($scope,Users, $auth) {
    $scope.itemsByPage = 15;
    $scope.displayedUsers = [];
    $scope.users=[];

    Users.get()
        .then(function (response) {
            $scope.users = response.data;
            $scope.displayedUsers = [].concat($scope.users);
        });
}]);