app.controller('StaffController',['$scope','Staff', function ($scope, Staff) {
    $scope.staff=[];
    $scope.displayedStaff=[];
    $scope.itemsByPage = 15;

    Staff.get()
        .then(function(response) {
            $scope.staff=response.data;
            $scope.displayedStaff=[].concat($scope.staff);
        });

    $scope.callServer = function(tableState) {
        console.log($scope.itemsByPage);
    };
}]);

