'use strict';

function DatePicker(){
    return {
        require: 'ngModel',
        restrict: 'A',
        scope: { format: "=" },
        link: function(scope, element, attrs, ngModel){
            if(typeof(scope.format) == "undefined"){ scope.format = "yyyy-mm-dd" }
            $(element).fdatepicker({format: scope.format}).on('changeDate', function(ev){
                scope.$apply(function(){
                    ngModel.$setViewValue(ev.date);
                });
            })
        }
    }
};

$(document).ready(function() {
    $('.Staff__Bio').readmore({
        speed: 150,
        lessLink:'<a class="Read-more" href="#"> <i class="fa fa-angle-left"></i> &nbsp;Read Less</a>',
        moreLink: '<a class="Read-more" href="#"><i class="fa fa-angle-right"></i> &nbsp;Read More</a>',
        collapsedHeight: 137
    });

    $('.close-button').click(function() {
        var myPlayer = document.querySelector('#youtube-video');
        myPlayer.stopVideo();
    });

    $('.Slick__testimonial').slick({
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.slick-event').slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });

    $('.Full_Testimonial').slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
});

$(document).foundation();
$(document).confirmWithReveal({
    modal_class: 'reveal-modal'
});

var app = angular.module('app', ['ui.router', 'satellizer','smart-table','ngFlash', 'ngFileUpload','720kb.datepicker','ngAnimate','angular-input-stars','rzModule', 'mm.foundation']
    , ['$interpolateProvider', function ($interpolateProvider)
    {
        $interpolateProvider.startSymbol('<%');
        $interpolateProvider.endSymbol('%>');
    }
    ]);

app.config(["$stateProvider", "$urlRouterProvider", "$authProvider", function($stateProvider, $urlRouterProvider, $authProvider) {
    // Satellizer configuration that specifies which API
    // route the JWT should be retrieved from
    $authProvider.loginUrl = '/api/login';

    $stateProvider
        .state('registration', {
            url: '/registration-details',
            templateUrl: '/partners/details',
        })

        // url will be /form/address
        .state('profile', {
            url: '/partners-profile',
            templateUrl: '/partners/profile'
        })

        // url will be /form/payment
        .state('ownership', {
            url: '/ownership-details',
            templateUrl: '/partners/ownership'
        })

        // url will be /form/payment
        .state('clients', {
            url: '/client-acquisition',
            templateUrl: '/partners/clients'
        });

    $urlRouterProvider.otherwise('/registration-details');

    }]);

app.controller('TestController', [ '$scope', function($scope) {

    $scope.$watch($scope.productRating, function() {
        console.log($scope.productRating);
    });
}]);


