/**
 * Login using Satellizer
 */
app.controller('LoginController', ['$scope', '$auth', '$window', '$http','Flash', function($scope, $auth,$window,$http,Flash) {

    if(localStorage.getItem('error')) {
        Flash.create('danger', localStorage.getItem('error'), 5000, {class: 'success fade', id: 'confirm-success'}, true);
        localStorage.removeItem('error');
    }

    $scope.login = function() {
        var credentials = {
            password : $scope.password,
            email: $scope.email
        };

        // Use Satellizer's $auth service to login
        $auth.login(credentials)
            .then(function(response) {
                if(response.data.error)
                {
                    Flash.create('danger', response.data.error, 5000, {class: 'danger fade', id: 'confirm-success'}, true);
                }
                else
                {
                    $window.location.href="/admin";
                }

                if(response.data.to)
                {
                    $window.location.href=response.data.to;
                    $scope.message = response.data.message;
                }


        }, function(response) {
                localStorage.setItem('error',response.data.error);
                Flash.create('danger', localStorage.getItem('error'), 5000, {class: 'success fade', id: 'confirm-success'}, true);
                localStorage.removeItem('error');
            });
    };

    $scope.logout = function() {
        $auth.logout().then(function() {
            $http.get('/users/logout').then(function(response){
                $window.location.href="/";
            });
        });
    };
}]);

app.controller('ConfirmationController', ['Flash','$scope','Post','$window', function(Flash, $scope, Post,$window) {

    var message = "Enter the 6 digit pin you received in your email to confirm login";
    Flash.create('success', message, 5000, {class: 'success', id: 'confirm-success'}, true);

    $scope.confirmLogin= function() {

        Post.post('/users/confirm', $scope.formData).then(
            function(response) {
                if(response.data.error) {
                    $window.location.href=response.data.to;
                    localStorage.setItem('error',response.data.error);
                }
                else
                {
                    $window.location.href="/admin";
                }
            },
            function(response) {
                $scope.errors=response.data;
            }
        );
    };
}]);

