app.controller('RatingsController', ['ProductRates', function(ProductRates) {
    var vm=this;
    ProductRates.get().then(
        function(response) {
            vm.products=response.data;
        });
}]);

app.controller('RatedProductController', ['ProductRate', '$http','$scope', 'Flash', function(ProductRate, $http, $scope, Flash) {
    var vm=this;

   $scope.$watch(vm.id, function () {
       ProductRate.get(vm.id).then(function(response) {
           vm.product = response.data;
       });
   });

    vm.rate = function () {
        $http.post('/api/products/rate/' + vm.id, { 'rating': vm.product.avgRating } ).then(
            function(response) {
                if(response.data.error) {
                    Flash.create('success', response.data.error, 5000, {class: 'success fade'}, true);
                }

                else {
                    Flash.create('danger', response.data.success, 5000, {class: 'danger fade'}, true);
                }

                ProductRate.get(vm.id).then(function(response) {
                    vm.product = response.data;
                });
            },
            function(response) {
                vm.errors = response.data;
            }
        );
    };
}]);
