app.controller('ProfilesController', ['$scope','PartnerProfile', function ($scope,PartnerProfile) {
    $scope.displayedProfiles=[];
    $scope.itemsByPage = 15;

    PartnerProfile.get()
        .then(function(response) {
            $scope.profiles=response.data;
            $scope.displayedProfiles=[].concat($scope.profiles);
        });
}]);

