app.controller('RolesController', ['Flash', 'Roles', '$scope', function (Flash, Roles, $scope) {
    $scope.roles=[];
    $scope.displayedRoles = [];

    Roles.get().then(
        function (response) {
            $scope.roles = response.data;
            $scope.displayedRoles = [].concat($scope.roles);
        },
        function (response) {
          $scope.errors = response.data;
        }
    );
}]);

app.controller('RoleDetailsController', ['$scope','RoleUser','PermissionRole', function($scope,RoleUser, PermissionRole) {
    $scope.users=[];
    $scope.displayedUsers=[];
    $scope.perms=[];
    $scope.displayedPerms=[];
    $scope.roleId=null;

    $scope.$watch('roleId', function () {
        RoleUser.get($scope.roleId).then(
            function(response) {
                $scope.users=response.data;
                console.log(response.data);
                $scope.displayedUsers=[].concat($scope.users);
            },
            function(response) {
                $scope.errors = response.data;
            }
        );
        PermissionRole.get($scope.roleId).then(
            function(response) {
                $scope.perms=response.data;
                $scope.displayedPerms=[].concat($scope.perms);
                console.log(response.data);
            },
            function (response) {
                $scope.errors = response.data;
            }
        );
    });

}]);