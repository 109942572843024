app.controller('YieldsController', ['$scope','Yield', function($scope, Yield) {
    $scope.yields = [];
    $scope.displayedCollection=[];
    $scope.itemsByPage = 10;

    $scope.callServer = function(tableState) {
        Yield.get(tableState).then(
            function (response) {
               $scope.yields = response.data.data;
                $scope.displayedCollection = [].concat($scope.yields);

                tableState.pagination.numberOfPages = response.data.meta.pagination.totalItemCount;
            },
            function(response) {
                $scope.errors = response.errors;
            });
    };
}]);