app.directive('myAlert', ['$animate','$interval', function($animate, $interval) {
    return {
        restrict: "AE",
        link : function(scope, element, attrs) {
            if(element.hasClass('alert-box')) {
                element.css({
                    position: 'fixed',
                    top: '100px',
                    right: '10px',
                    padding: '15px'
                });

                jQuery(element).slideDown(500);

                $interval( function () {
                    jQuery(element).fadeOut(1000);
                }, 5000);
            }
        }
    }
}]);