app.controller('NewsController', ['$scope', 'News', function($scope, News) {
    $scope.itemsByPage = 10;

    $scope.callServer = function callServer(tableState) {
        News.get(tableState)
            .then(function (response) {
                $scope.news = response.data.data;
                $scope.displayedNews=[].concat($scope.news);
                tableState.pagination.numberOfPages = response.data.meta.pagination.totalItemCount;
            });
    };
    $scope.convert =  function ( date ) {
        return new Date(date.replace(/-/g, "/"));
    };
}]);

app.controller('ArticlesController', ['$scope', 'Article', function($scope, Article) {
    $scope.itemsByPage = 10;

    $scope.callServer = function callServer(tableState) {
        Article.get(tableState)
            .then(function (response) {
                $scope.articles = response.data.data;
                $scope.displayedArticles=[].concat($scope.articles);
                tableState.pagination.numberOfPages = response.data.meta.pagination.totalItemCount;
            });
    };
    $scope.convert =  function ( date ) {
        return new Date(date.replace(/-/g, "/"));
    };
}]);