app.controller('GroupsController', ['$scope','Groups','$auth', function($scope, Groups,$auth ) {
    $scope.groups =[];
    $scope.displayedGroups=[];
    $scope.itemsByPage=15;

    Groups.get().then(function(response) {
        $scope.groups=response.data;
        $scope.displayedGroups=[].concat($scope.groups);
    });

}]);