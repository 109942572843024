app.controller('AttachmentController', ['$scope', 'Upload', '$timeout','Attachments', function ($scope, Upload, $timeout, Attachments) {
    $scope.uploadAttachments = function(file) {
        file.upload = Upload.upload({
            url: '/partners/applications/uploads/'+$scope.id,
            data: { file_id: $scope.file, description: $scope.description, file:$scope.attachmentFile },
        });

        file.upload.then(function (response) {
            $timeout(function () {
                file.result = response.data;

                loadAttachments();
            });
        }, function (response) {
            if (response.status > 0)
                $scope.errorMsg = response.status + ': ' + response.data;
        }, function (evt) {
            // Math.min is to fix IE which reports 200% sometimes
            file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
        });
    };

    $scope.$watch('id', function () {
        loadAttachments();
    });

    var loadAttachments = function() {
        Attachments.get($scope.id).then(function(response) {
            $scope.attachments = response.data;
        });
    };
}]);