app.controller('IFAsController', ['IFAs', '$scope', function(IFAs, $scope) {
    $scope.callServer = function(tableState) {
        IFAs.get(tableState).then(function (response) {
                $scope.ifas = response.data.data;
            console.log(response.data);
                $scope.displayedIFAs=[].concat($scope.ifas);
                tableState.pagination.numberOfPages = response.data.meta.pagination.totalItemCount;
            });
    };
}]);

app.controller('IFAApplicationsController', ['IFAApplications', '$scope', function(IFAApplications, $scope) {
    $scope.callServer = function(tableState) {
        IFAApplications.get(tableState).then(function (response) {
            console.log(response.data.data);
                $scope.applications = response.data.data;
                $scope.displayedApplications=[].concat($scope.applications);
                tableState.pagination.numberOfPages = response.data.meta.pagination.totalItemCount;
            });
    };
}]);