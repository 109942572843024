app.controller('UploadsController',['$scope', 'Uploads', function($scope, Uploads) {
    $scope.displayedCollection = [];
    $scope.itemsByPage = 10;
    $scope.gallery_id = null;

    $scope.$watch('gallery_id', function () {

        Uploads.getUpload($scope.gallery_id)
            .then(function(response) {
                $scope.uploads = response.data;
                $scope.displayedCollection = [].concat($scope.uploads);
            }, function(response) {
                $scope.errors = response.data;
            });

        });

}]);